<template>
    <div>
        <ts-page-title
            :title="$t('shop.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('shop.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div
                    class="tw-flex tw-justify-between tw-items-center"
                >
                    <div>
                        <a-button
                            type="primary"
                            @click="addNew"
                            v-if="$can('create-shop-profiles')"
                        >
                            {{ $t('addNew') }}
                        </a-button>
                    </div>
                    <div class="tw-space-x-3">
                        <a-radio-group
                            v-model="shopTypeId"
                            @change="fetchData()"
                        >
                            <a-radio-button :value="1">
                                {{ $t('shop.normal') }}
                            </a-radio-button>
                            <a-radio-button :value="2">
                                {{ $t('shop.vip') }}
                            </a-radio-button>
                        </a-radio-group>

                        <a-radio-group v-model="isActive" @change="fetchData()">
                            <a-radio-button :value="1">
                                {{ $t('shop.active') }}
                            </a-radio-button>
                            <a-radio-button :value="0">
                                {{ $t('shop.inactive') }}
                            </a-radio-button>
                        </a-radio-group>
                        <a-tooltip :title="$t('shop.search')">
                            <a-input-search
                                v-model="search"
                                :placeholder="$t('shop.search')"
                                style="width: 200px"
                            />
                        </a-tooltip>
                    </div>
                </div>
            </ts-panel-wrapper>
            <ts-table
                :columns="columns"
                :records="resources"
                :loading="loading"
            >
                <template v-slot="{ record }">
                    <td>{{ record.shop_code }}</td>
                    <td>{{ record.shop_name }}</td>
                    <td>{{ record.phone_number }}</td>
                    <td>{{ record.agency_name }}</td>
                    <td>{{ record.business_category }}</td>
                    <td class="tw-text-center">{{ record.shop_type }}</td>
                    <td class="tw-text-right">
                        {{ $currencyFormat(record.standard_fee_usd) }}
                    </td>
                    <td class="tw-text-right">
                        {{ $currencyFormat(record.express_fee_usd) }}
                    </td>
                    <td class="tw-text-right">
                        {{ $currencyFormat(record.standard_driver_fee_usd) }}
                    </td>
                    <td class="tw-text-right">
                        {{ $currencyFormat(record.express__driver_fee_usd) }}
                    </td>
                    <td class="tw-text-center">
                        <span
                            class="badge bg-primary"
                            v-if="record.acknowledge_tc"
                            >Yes</span
                        >
                        <span class="badge bg-secondary" v-else>No</span>
                    </td>
                    <td class="tw-text-center">
                        <span class="badge bg-primary" v-if="record.is_active"
                            >Yes</span
                        >
                        <span class="badge bg-secondary" v-else>No</span>
                    </td>
                    <td class="text-center tw-space-x-3">
                        <a
                            v-if="$can('update-shop-profiles')"
                            href="#"
                            v-tooltip="$t('edit')"
                            class="text-primary mr-2"
                            @click.prevent="edit(record)"
                        >
                            <i class="fas fa-edit"></i>
                        </a>

                        <a-popconfirm
                            placement="leftTop"
                            :title="$t('are_you_sure_to_proceed')"
                            :ok-text="$t('yes')"
                            :cancel-text="$t('no')"
                            @confirm="onInitial(record)"
                        >
                            <a href="#" :disabled="record._deleting">
                                <i
                                    class="fas fa-circle-notch spin"
                                    v-if="record._deleting"
                                ></i>
                                <i class="fas fa-sync" v-else></i>
                            </a>
                        </a-popconfirm>
                    </td>
                </template>
            </ts-table>
            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
        </ts-panel>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { debounce } from 'lodash'

export default {
    name: 'shopIndex',
    data () {
        return {
            loading: false
        }
    },
    computed: {
        ...mapState('agency/shop', ['resources', 'pagination']),
        search: {
            get () {
                return this.$store.state.agency.shop.search
            },
            set (newValue) {
                this.$store.commit('agency/shop/SET_SEARCH', newValue)
                this.$store.commit('agency/shop/RESET_CURRENT_PAGE')
            }
        },
        shopTypeId: {
            get () {
                return this.$store.state.agency.shop.shop_type_id
            },
            set (newValue) {
                this.$store.commit('agency/shop/SET_SHOP_TYPE', newValue)
            }
        },
        isActive: {
            get () {
                return this.$store.state.agency.shop.isActive
            },
            set (newValue) {
                this.$store.commit('agency/shop/SET_ACTIVE', newValue)
            }
        },
        columns () {
            return [
                { name: this.$t('shop.shopCode') },
                { name: this.$t('shop.name') },
                { name: this.$t('shop.phoneNumber') },
                { name: this.$t('shop.agency'), style: 'width: 250px' },
                {
                    name: this.$t('shop.businessCategory'),
                    style: 'width: 180px'
                },
                { name: this.$t('shop.shopType'), style: 'width: 80px' },
                {
                    name: this.$t('shop.standardFeeUsd'),
                    style: 'width: 180px; text-align:right'
                },
                {
                    name: this.$t('shop.expressFeeUsd'),
                    style: 'width: 180px; text-align:right'
                },
                {
                    name: this.$t('shop.standardDriverFeeUsd'),
                    style: 'width: 180px; text-align:right'
                },
                {
                    name: this.$t('shop.expressDriverFeeUsd'),
                    style: 'width: 180px; text-align:right'
                },
                { name: this.$t('shop.approved'), style: 'text-align:center' },
                { name: this.$t('shop.active'), style: 'text-align:center' },
                {
                    name: this.$t('actions'),
                    style: 'text-align:center; width: 1px;'
                }
            ]
        }
    },
    methods: {
        addNew () {
            this.$router.push({ name: 'shop-create' })
        },
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('agency/shop/fetch', { ...attributes })
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                    this.$notify({ type: 'error', text: error.message })
                })
        },
        edit (record) {
            this.$store.commit('agency/shop/SET_EDIT_DATA', record)
            this.$router.push({
                name: 'shop-edit',
                params: { id: record.shop_id }
            })
        },
        onInitial (record) {
            record._deleting = true
            this.$store
                .dispatch('agency/shop/initStock', record.shop_id)
                .then(response => {
                    this.$notify({ type: 'success', text: response.message })
                    this.fetchData()
                })
                .catch(error => {
                    this.$notify({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },
    watch: {
        search: debounce(function () {
            this.fetchData()
        }, 500)
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchData()
        })
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('agency/shop/RESET_STATE')
        next()
    }
}
</script>
